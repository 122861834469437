import { useState } from "react";
import langue_francaise from "../../ressources/images/French_language.svg";
import language_english from "../../ressources/images/English_language.svg";
import Select from "react-select";
import i18n from "../../I18next/i18n";

const options = [
    { value: 'fr', label: 'fr', image: langue_francaise },
    { value: 'en', label: 'en', image: language_english },
];

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'orange',
        boxShadow: state.isFocused ? '0 0 0 1px orange' : null,
        '&:hover': {
            borderColor: 'orange',
        },
        cursor: 'pointer',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: 'orange',
        '&:hover': {
            color: 'orange',
        },
    }),
    input: (provided) => ({
        ...provided,
        // Prevent keyboard on mobile
        opacity: 0,
        height: 0,
        position: 'absolute',
        pointerEvents: 'none',
    }),
    valueContainer: (provided) => ({
        ...provided,
        cursor: 'pointer',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: 4,
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        backgroundColor: state.isSelected ? 'orange' : state.isFocused ? '#fff3e0' : null,
        '&:active': {
            backgroundColor: '#fff3e0',
        },
    }),
};

export default function LanguageSelector() {
    const [selectedOption, setSelectedOption] = useState(options[0]);

    const handleChange = (option) => {
        setSelectedOption(option);
        i18n.changeLanguage(option.value);
    };

    useState(() => {
        const currentLang = i18n.language;
        const currentOption = options.find(opt => opt.value === currentLang);
        if (currentOption) {
            setSelectedOption(currentOption);
        }
    }, []);

    return (
        <Select
            value={selectedOption}
            options={options}
            styles={customStyles}
            formatOptionLabel={country => (
                <div className="country-option">
                    <img
                        src={country.image}
                        alt={`${country.value} language`}
                        width="30"
                        height="30"
                    />
                </div>
            )}
            onChange={handleChange}
            isSearchable={false}
            components={{
                IndicatorSeparator: () => null
            }}
        />
    );
}
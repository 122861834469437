import React, { useState, useRef, useEffect } from 'react';
import { Menu, ArrowLeft, Plus, X, GraduationCap, Code, Target } from 'lucide-react';
import logo from '../ressources/images/logo-ormaes-text.svg';
import { useTranslation } from 'react-i18next';
import LanguageSelector from "./language-selector/LanguageSelector";

const MenuDrawer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentSubmenu, setCurrentSubmenu] = useState(null);
    const drawerRef = useRef(null);
    const goldColor = 'rgb(255 151 3)';

    const toggleDrawer = () => setIsOpen(!isOpen);

    const closeDrawer = () => {
        setIsOpen(false);
        setCurrentSubmenu(null);
    };

    const { t } = useTranslation();

    const menuItems = [
        { name: t("footer_accueil"), responsive: true, link: '/'},
        { name: t("footer_qui"), responsive: true, link: '/qui-sommes-nous'},
        {
            name: t("menudrawer_item1"),
            submenu: [
                { name: t("menudrawer_subitem1.1"), icon: GraduationCap, link: '/notre-offre' },
                { name: t("menudrawer_subitem1.2"), icon: Code, link: '/notre-offre' },
                { name: t("menudrawer_subitem1.3"), icon: Target, link: '/notre-offre' },
            ],
        },
        { name: t("menudrawer_item4"), link: '/carrieres' },
        { name: t("Contact"), responsive: true, link: '/contact'},
    ];

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                closeDrawer();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    const handleNavigation = (e) => {
        setTimeout(closeDrawer, 100);
    };

    const renderSubmenu = () => {
        const submenuItems = menuItems.find((item) => item.name === currentSubmenu)?.submenu;
        if (!submenuItems) return null;

        return (
            <div className="submenu-container transition-transform duration-500 ease-in-out transform translate-x-0">
                <div className="p-4">
                    <div style={{paddingLeft: '3rem', paddingRight: '3rem'}}>
                        <button
                            className="flex items-center text-gray-600 hover:text-[#ff9703] mb-6"
                            onClick={() => setCurrentSubmenu(null)}
                        >
                            <ArrowLeft size={20} className="mr-2"/>
                            {t("menudrawer_retour")}
                        </button>
                        <h2 className="text-xl font-bold mb-4" style={{marginTop: '3rem'}}>
                            {currentSubmenu}
                        </h2>

                        <ul className="space-y-4" style={{marginBottom: '5rem'}}>
                            {submenuItems.map((item, index) => {
                                const normalizeString = (str) => {
                                    return str
                                        .toLowerCase()
                                        .normalize("NFD")
                                        .replace(/[\u0300-\u036f]/g, '')
                                        .replace(/ /g, '-')
                                        .replace(/[^\w-]/g, '');
                                };

                                return (
                                    <li key={index} className="relative border-b border-l-black">
                                        <a
                                        href={`notre-offre#${normalizeString(item.name)}`}
                                        className="flex items-center justify-between w-full py-3 px-3 rounded-lg hover:bg-gray-100"
                                        onClick={handleNavigation}
                                        >
                                        <div className="flex items-center gap-3 w-full">
                                            <item.icon size={25} style={{ color: goldColor }} />
                                            <span className="font-medium">{item.name}</span>
                                            <ArrowLeft size={20} className="ml-auto rotate-180 flex-shrink-0" />
                                        </div>
                                    </a>
                                </li>
                            );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    // Separate container for language selector to prevent event bubbling
    const LanguageSelectorContainer = () => {
        const handleLanguageClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };

        return (
            <div
                className="block xl:hidden mb-4"
                onClick={handleLanguageClick}
                onMouseDown={(e) => e.stopPropagation()}
            >
                <LanguageSelector />
            </div>
        );
    };

    return (
        <div className="relative">
            {!isOpen && (
                <button
                    onClick={toggleDrawer}
                    className="top-5 left-4 z-50 text-white p-2 rounded-md"
                    aria-label="Open menu"
                >
                    <Menu size={24} style={{ color: goldColor }} />
                </button>
            )}
            <div
                ref={drawerRef}
                className={`fixed top-0 left-0 h-full bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'} overflow-y-auto w-full xl:w-1/4`}
            >
                <div className="flex flex-col h-full">
                    <div className="p-4" style={{
                        paddingLeft: '3rem',
                        paddingRight: '3rem',
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        zIndex: 10
                    }}>
                        <div className="flex items-center justify-between mb-6">
                            <a href="/" onClick={handleNavigation}>
                                <img src={logo} alt="Ormaes" style={{height: '3rem'}}/>
                            </a>
                            <button onClick={toggleDrawer} className="text-gray-500 hover:text-gray-700">
                                <X size={24}/>
                            </button>
                        </div>
                    </div>

                    <div className="flex-1 overflow-auto">
                        {!currentSubmenu ? (
                            <nav>
                                <ul className="space-y-4 px-6">
                                    {menuItems.map((item, index) => (
                                        <li key={index}
                                            className={`border-b border-l-black ${item.responsive ? 'block xl:hidden' : ''}`}>
                                            {item.submenu ? (
                                                <button
                                                    onClick={() => setCurrentSubmenu(item.name)}
                                                    className="flex items-center justify-between w-full py-5 rounded-lg hover:bg-gray-100 text-left font-medium font-roboto text-2xl"
                                                >
                                                    <span>{item.name}</span>
                                                    <Plus size={20} className="transform"/>
                                                </button>
                                            ) : (
                                                <a
                                                    href={item.link}
                                                    className={`${item.responsive ? 'block xl:hidden' : ''} flex items-center justify-between w-full py-5 rounded-lg hover:bg-gray-100 text-left font-medium font-roboto text-2xl`}
                                                    onClick={handleNavigation}
                                                >
                                                    <span>{item.name}</span>
                                                </a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        ) : renderSubmenu()}
                    </div>

                    <div className="p-4" style={{paddingTop: '1rem'}}>
                        <LanguageSelectorContainer />
                        <div>
                            <a href="/contact" onClick={handleNavigation}>
                                <button
                                    className="w-full text-white py-3 px-4 rounded-md hover:bg-gray-800"
                                    style={{backgroundColor: goldColor}}
                                >
                                    {t("menudrawer_demarrer_projet")}
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuDrawer;
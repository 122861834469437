import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../ressources/images/logo-ormaes-text.svg";
import { useTranslation } from 'react-i18next';
import LanguageSelector from "./language-selector/LanguageSelector";
import langue_francaise from "../ressources/images/French_language.svg";
import language_english from "../ressources/images/English_language.svg";
import MenuDrawer from "../components/MenuDrawer";


export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  const navigateTo = (path) => {
    navigate(path); 
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false); 
    };

    if (isOpen) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="fixed h-24 top-0 w-full border-b-4 bg-white border-primary flex items-center font-mtserrat z-40">
      <nav className="flex justify-between items-center w-full wrapper">
        <div className="flex items-center justify-between" style={{gap: "1rem"}}>
          <Link to="/">
            <img src={logo} alt="Logo Ormaes" className="z-10" />
          </Link>
          <MenuDrawer></MenuDrawer>
        </div>
        <ul className={`absolute left-0 h-100vh top-24 font-bold bg-slate-100 text-primGrey text-[16px] transition-all duration-500 ease-out ${ isOpen ? "" : "top-[-500px]" } w-full xl:w-0 xl:static xl:flex xl:justify-between xl:min-w-[60%] xl:bg-white`} >
          <li>
            <Link to="/" className="item-link-responsive xl:item-link" onClick={() => { navigateTo("/"); handleLinkClick(); }} >
              {t("header_accueil")}
            </Link>
          </li>
          <li>
            <Link to="qui-sommes-nous" className="item-link-responsive xl:item-link" onClick={() => { navigateTo("qui-sommes-nous"); handleLinkClick(); }} >
              {t("header_qui")}
            </Link>
          </li>
          <li>
            <Link to="contact" className="item-link-responsive xl:btn" onClick={() => { navigateTo("contact"); handleLinkClick(); }} >
              {t("header_contact")}
            </Link>
          </li>
          <li className="flex items-center">
            {!isLargeScreen && isOpen ? (
              <>
                <img src={langue_francaise} alt="FR" className="cursor-pointer mr-2" onClick={() => changeLanguage('fr')} width={30} height={20} />
                <img src={language_english} alt="EN" className="cursor-pointer" onClick={() => changeLanguage('en')} width={30} height={20} />
              </>
            ) : (
              <LanguageSelector/>
            )}
          </li>
          <li>
            &nbsp;
          </li>
        </ul>
      </nav>
    </header>
  );
}

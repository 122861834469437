import React from 'react';
import Button from "../../../components/Button";
import { useTranslation } from 'react-i18next';

export default function About() {
  const { t } = useTranslation();
  return (
    <div className='mt-32 mx-auto wrapper'>
        <h1 className="text-4xl font-bold text-center md:text-5xl md:mb-20" data-aos="zoom-in">
            {t("about_qui")} 
            <span className="text-primOrange"> {t("about_sommes-nous")} ?</span> 
        </h1>
        <div className="w-full xl:flex xl:gap-16 xl:items-center" data-aos="zoom-in">
          <div className="img-background2 bg-cover bg-no-repeat w-full h-[250px] bg-red mx-auto rounded-[26px] relative mt-12 mb-12 md:w-[570px] md:h-[330px] xl:w-1/2 xl:h-[450px] xl:max-h-3xl"></div>
          <div className='xl:w-1/2' data-aos="fade-right">
            <p className='paragraph'>
              {t("about_ormaes_1")}     
            </p>
            <p className='paragraph mt-5'>
            {t("about_ormaes_2")} 
            </p>
            <div className="flex justify-center mt-7">
                <Button to="qui-sommes-nous" texte={t("about_ormaes_decouvrir")}/>
            </div>
          </div>
        </div>
    </div>
  )
}
